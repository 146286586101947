<template>
  <div class="home-wrap">
    <navigation-area />
    <v-main>
      <!-- <div class="contents-wrap" v-if="route.path === '/'">
        <dash-board/>
      </div> -->
      <!-- <div class="contents-wrap" v-else> -->
      <!-- <router-view/> -->
      <!-- </div> -->
    </v-main>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import NavigationArea from "@/components/NavigationArea.vue";
// import DashBoard from '@/components/layout/DashBoard.vue';
export default {
  name: "BasicLayout",
  data: () => ({}),
  components: {
    NavigationArea,
    // DashBoard,
  },
  watch: {},
  computed: {
    ...mapGetters({
      userInfo: "auth/userInfo",
      companyInfo: "auth/companyInfo",
      route: "router/route",
    }),
  },
  created() {
    if (this.route.path === "/") {
      this.$router.push("/users");
    }
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      alert: "dialog/alert",
    }),
  },
};
</script>
<style lang="scss">
.home-wrap {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  .contents-wrap {
    padding: 30px 30px 60px;
    min-width: 1260px;
    .text-label {
      margin: 0;
      flex-shrink: 0;
      padding: 4px 8px;
      background-color: #f2f2f2;
      border-radius: 100px;
      font-size: 0.8rem;
      align-self: center;
      width: max-content;
    }
    .v-data-table__wrapper {
      // border: 1px solid #ddd;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      // border-radius: 10px;
      thead > tr > th {
        background-color: #f9f9f9;
      }
    }
    .title-section {
      margin-bottom: 20px;
      display: flex;
      align-content: center;
      justify-content: space-between;
      h2 {
        font-size: 1.7rem;
        font-weight: 700;
        margin: 0;
      }
      .btnWrap {
        display: flex;
      }
    }
  }
}
.vue-recycle-scroller__item-wrapper {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
