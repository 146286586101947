import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Home from "../views/Home.vue";
import SignIn from "../views/SignIn.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    meta: {
      needAuth: false,
      needLogin: true,
    },
    children: [
      {
        path: "/expertlist",
        component: () => import(/* webpackChunkName: "expertList" */ "../views/expert/LineUp.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/expertview",
        name: "expertview",
        component: () =>
          import(/* webpackChunkName: "expertView" */ "../views/expert/ExpertView.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/products",
        component: () =>
          import(/* webpackChunkName: "productManage" */ "../views/expert/ProductManage.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/expertorder",
        component: () =>
          import(/* webpackChunkName: "productManage" */ "../views/expert/ExposureOrder.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/terms",
        component: () =>
          import(/* webpackChunkName: "productManage" */ "../views/contents/TermsManage.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/users",
        component: () =>
          import(/* webpackChunkName: "totalUsers" */ "../views/users/TotalUsers.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/userview",
        name: "userview",
        component: () => import(/* webpackChunkName: "userView" */ "../views/users/UserView.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/expired",
        component: () =>
          import(/* webpackChunkName: "expiredUser" */ "../views/users/ExpiredUser.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/viptrial",
        component: () => import(/* webpackChunkName: "vipTrial" */ "../views/users/VipTrial.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/payments",
        component: () =>
          import(/* webpackChunkName: "paymentList" */ "../views/payment/PaymentList.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/refund",
        component: () =>
          import(/* webpackChunkName: "refundList" */ "../views/payment/RefundList.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/manage",
        component: () =>
          import(/* webpackChunkName: "adminManage" */ "../views/manage/AdminManage.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/manual",
        component: () => import(/* webpackChunkName: "eventList" */ "../views/manual/Manual.vue"),
        needAuth: false,
        needLogin: true,
      },
      {
        path: "/manageview",
        name: "manageview",
        component: () =>
          import(/* webpackChunkName: "manageView" */ "../views/manage/ManageView.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/smslist",
        component: () => import(/* webpackChunkName: "smsList" */ "../views/sms/SmsList.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/sendsms",
        component: () => import(/* webpackChunkName: "sendSme" */ "../views/sms/SendSms.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/board",
        name: "board",
        component: () =>
          import(/* webpackChunkName: "boardManage" */ "../views/contents/BoardManage.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/noticeregist",
        name: "noticeregist",
        component: () =>
          import(/* webpackChunkName: "noticeRegist" */ "../views/contents/NoticeRegist.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/banner",
        component: () =>
          import(/* webpackChunkName: "bannerList" */ "../views/contents/BannerList.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/bannerRegist",
        component: () =>
          import(/* webpackChunkName: "bannerRegist" */ "../views/contents/BannerRegist.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/bannerView",
        name: "bannerView",
        component: () =>
          import(/* webpackChunkName: "bannerView" */ "../views/contents/BannerView.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/calendarList",
        name: "CalendarList",
        component: () =>
          import(/* webpackChunkName: "calendarList" */ "../views/contents/CalendarList.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/calendarView",
        name: "CalendarView",
        component: () =>
          import(/* webpackChunkName: "calendarView" */ "../views/contents/CalendarView.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/lectureSeries",
        name: "LectureSeries",
        component: () =>
          import(/* webpackChunkName: "lectureSeries" */ "../views/lecture/LectureSeries.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      // {
      //   path: "/lecture-list",
      //   name: "LectureList",
      //   component: () =>
      //     import(/* webpackChunkName: "lectureList" */ "../views/lecture/LectureList.vue"),
      //   meta: {
      //     needAuth: false,
      //     needLogin: true,
      //   },
      // },
      // {
      //   path: "/lecture-regist",
      //   name: "LectureRegist",
      //   component: () =>
      //     import(/* webpackChunkName: "lectureRegist" */ "../views/lecture/LectureRegist.vue"),
      //   meta: {
      //     needAuth: false,
      //     needLogin: true,
      //   },
      //   props: true,
      // },
      {
        path: "/lecture-group-regist",
        name: "LectureGroupRegist",
        component: () =>
          import(
            /* webpackChunkName: "lectureGroupRegist" */ "../views/lecture/LectureGroupRegist.vue"
          ),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/lecture-order",
        name: "LectureOrder",
        component: () =>
          import(/* webpackChunkName: "lectureOrder" */ "../views/lecture/LectureOrder.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/smstemplate",
        component: () =>
          import(/* webpackChunkName: "smsTemplate" */ "../views/sms/SmsTemplate.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/templatereg",
        component: () =>
          import(/* webpackChunkName: "templateRegist" */ "../views/sms/TemplateRegist.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/eventlist",
        component: () =>
          import(/* webpackChunkName: "eventList" */ "../views/contents/EventList.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/eventregist",
        component: () =>
          import(/* webpackChunkName: "eventRegist" */ "../views/contents/EventRegist.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/eventview",
        name: "eventview",
        component: () =>
          import(/* webpackChunkName: "eventView" */ "../views/contents/EventView.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/eventwinnerview",
        name: "eventwinnerview",
        component: () =>
          import(/* webpackChunkName: "eventView" */ "../views/contents/EventWinnerView.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/vodmanage",
        component: () =>
          import(/* webpackChunkName: "vodManage" */ "../views/contents/VodManage.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/overseasschedule",
        name: "OverseasSchedule",
        component: () =>
          import(/* webpackChunkName: "vodManage" */ "../views/contents/OverseasSchedule.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/expertcalc",
        component: () =>
          import(/* webpackChunkName: "vodManage" */ "../views/payment/CalcList.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/faqregist",
        name: "faqregist",
        component: () =>
          import(/* webpackChunkName: "faqRegist" */ "../views/contents/FaqRegist.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/dailyregist",
        name: "dailyregist",
        component: () =>
          import(/* webpackChunkName: "dailyRegist" */ "../views/contents/DailyRegist.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/reviewregist",
        name: "reviewregist",
        component: () =>
          import(/* webpackChunkName: "reviewRegist" */ "../views/contents/ReviewRegist.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
        props: true,
      },
      {
        path: "/ipmanage",
        name: "ipmanage",
        component: () =>
          import(/* webpackChunkName: "ipManage" */ "../views/contents/IpManage.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: "/vip",
        name: "vipvideomanage",
        component: () =>
          import(/* webpackChunkName: "ipManage" */ "../views/contents/VipVideoManage.vue"),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
    ],
  },
  {
    path: "/signin",
    name: "SignIn",
    meta: {
      needAuth: false,
      needLogin: false,
    },
    component: SignIn,
  },
  {
    path: "/start",
    component: () => import(/* webpackChunkName: "authCheck" */ "../views/AuthCheck.vue"),
    meta: {
      needAuth: false,
      needLogin: false,
    },
  },
  {
    path: "/liveState",
    component: () => import(/* webpackChunkName: "authCheck" */ "../views/LiveState.vue"),
    meta: {
      needAuth: false,
      needLogin: false,
    },
  },
  {
    path: "/vodAvailable",
    component: () => import(/* webpackChunkName: "authCheck" */ "../views/VodAvailable.vue"),
    meta: {
      needAuth: false,
      needLogin: false,
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {},
});

router.beforeEach(async (to, from, next) => {
  // 허용되지 않은 경로 블럭
  if (!to.matched.length) {
    store.dispatch("dialog/alert", ["error", "잘못된 접근입니다."]);
    router.push("/");
    next(false);
  }
  next();
});

router.afterEach(async (to) => {
  store.commit("router/route", to);
  await router.checkAuth();
});

router.checkAuth = async () => {
  const to = router.currentRoute;
  const connected = store.getters["auth/connected"];
  const initialized = store.getters["auth/initialized"];
  const verified = store.getters["auth/verified"];
  const userInfo = store.getters["auth/userInfo"];
  const companyInfo = store.getters["auth/companyInfo"];
  const permission = userInfo ? userInfo.permission : null;

  if (connected && initialized) {
    if (!verified && to.meta.needLogin) {
      await router.push("/signin");
    } else if (verified && to.path === "/signin" && to.path !== "/") {
      await router.push("/");
    } else if (verified && to.meta.needAuth) {
      if (to.meta.category !== "boards") {
        const authBool =
          to.meta.func === undefined
            ? permission[to.meta.category].menu[to.meta.menu].bool
            : permission[to.meta.category].menu[to.meta.menu].func[to.meta.func].bool;
        if (authBool === false) {
          store.dispatch("dialog/alert", ["error", "접근 권한이 없습니다."], false);
          router.back();
        }
      }
    }
  } else if (companyInfo.code !== "") {
    // localStorage.removeItem(`DCurve.${companyInfo.code}.authToken`);
    if (to.path !== "/signin" && to.meta.needLogin) {
      await router.push("/signin");
    }
  }
};
router.goBack = () => {
  router.back();
};
// 전역 참조 허용 함수
(() => {
  window.$router = {
    goBack: router.goBack,
  };
})();

export default router;
