import axios from "axios";
import store from "@/store";

const protocol = process.env.VUE_APP_API_PROTOCOL;
const host = process.env.VUE_APP_API_HOST;
const port = process.env.VUE_APP_API_PORT;
const prefix = process.env.VUE_APP_API_URIPREFIX;

const axiosInstance = axios.create({
  baseURL: `${protocol}://${host}:${port}${prefix}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  const token = document.cookie
    .split("; ")
    .find((row) => row.startsWith("XSRF-TOKEN="))
    ?.split("=")[1];
  if (token) {
    config.headers["X-XSRF-TOKEN"] = token;
  } else {
    console.warn("XSRF-TOKEN not found, proceeding without it.");
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.config && error.response) {
      console.error(error.response);
      switch (error.response.status) {
        case 401: {
          const message = error.response.data.message;
          if (message === "UNAUTHORIZED") {
            try {
              await store.dispatch("auth/refreshToken");
              return axiosInstance.request(error.config);
            } catch (refreshError) {
              await store.dispatch("auth/logout");
              return Promise.reject(refreshError);
            }
          } else if (message === "REFRESH_FAIL") {
            await store.dispatch("auth/logout");
            return Promise.reject(error);
          }
          break;
        }
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
