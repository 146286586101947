import Vue from "vue";
import axios from "@/util/axios";

const menuIcons = {
  ip: "mdi-ip",
  auth: "mdi-key",
  board: "mdi-clipboard-multiple",
  group: "mdi-sitemap",
  account: "mdi-account-cog",
  work: "mdi-clipboard-text-clock",
  cs: "mdi-headset",
  product: "mdi-archive-edit",
  db: "mdi-account-details",
  customer: "mdi-account-multiple",
  payment: "mdi-credit-card-edit",
  sales: "mdi-finance",
  leader: "mdi-account-tie",
  salary: "mdi-clipboard-account",
  boards: "mdi-clipboard-edit-outline",
  pg: "mdi-credit-card-plus",
  rooms: "mdi-chat-plus",
  ranking: "mdi-account-account",
  extra: "mdi-account-key",
};
export default {
  namespaced: true,
  state: {
    connected: false,
    initialized: false,
    userInfo: null,
    companyInfo: {
      name: "퓨처온",
      code: "d-curve",
      path: "/path/image.jpg",
      receptionNumber: "",
    },
    verified: null,
    menus: null,
    isRefreshing: false,
  },
  getters: {
    userInfo(state) {
      return state.userInfo;
    },
    companyInfo(state) {
      return state.companyInfo;
    },
    connected(state) {
      return state.connected;
    },
    initialized(state) {
      return state.initialized;
    },
    verified(state) {
      return state.userInfo != null;
    },
    menus(state) {
      return state.menus;
    },
  },
  mutations: {
    connected(state, value) {
      state.connected = value;
    },
    initialized(state, value) {
      state.initialized = value;
    },
    userInfo(state, value) {
      state.userInfo = value;
    },
    companyInfo(state, value) {
      state.companyInfo = value;
    },
    // token(state, value) {
    //   state.token = value;
    // },
    verified(state, value) {
      state.verified = value;
    },
    menus(state, value) {
      state.menus = value;
    },
    refresh_request(state, isRefreshing) {
      state.isRefreshing = isRefreshing;
    },
  },
  actions: {
    "socket.connect": ({ state, commit, dispatch }) => {
      commit("connected", true);
      console.log("socket connected");
      dispatch("appInitialized");
      if (state.userInfo === null)
        dispatch("dialog/alert", ["success", "접속되었습니다."], { root: true });
    },
    "socket.error": (_, error) => {
      console.log("socket error:", error);
    },
    "socket.reconnect_failed": ({ state, commit, dispatch }, error) => {
      console.log("socket error:", error);
      console.log("reconnect_failed");
      commit("initialized", false);
      commit("connected", false);
      commit("verified", false);
      commit("userInfo", null);
      localStorage.removeItem(`DCurve.${state.companyInfo.code}.authToken`);
      dispatch(
        "dialog/alert",
        ["error", "재접속에 실패하였습니다. 인터넷 상태를 확인해주세요.", false],
        { root: true }
      );
    },
    "socket.disconnect": ({ dispatch }, _, resp) => {
      console.log("socket disconnect:", resp);
      dispatch("dialog/alert", ["error", "통신 오류가 발생했습니다."], { root: true });
    },
    async logout({ commit }) {
      const result = await axios.post(`/init/admLogout`);
      if (result.data.message === "LOGOUT_SUCCESS") {
        commit("verified", false);
        commit("initialized", true);
        commit("userInfo", null);
        commit("dialog/progress", false, { root: true });
      }
    },

    listenSSE({ dispatch, state }) {
      const adminId = state.userInfo?.admin_id;
      if (!adminId) return;

      const protocol = process.env.VUE_APP_API_PROTOCOL;
      const host = process.env.VUE_APP_API_HOST;
      const port = process.env.VUE_APP_API_PORT;
      const prefix = process.env.VUE_APP_API_URIPREFIX;

      const eventSource = new EventSource(
        `${protocol}://${host}:${port}${prefix}/subscribe?id=${adminId}`
      );
      eventSource.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.event === "logout" && message.id === adminId) {
          dispatch("dialog/alert", ["warning", "다른 장치에서 로그인되어 로그아웃 되었습니다."], {
            root: true,
          });
          dispatch("logout");
        }
      };

      eventSource.onerror = () => {
        eventSource.close();
        setTimeout(() => {
          dispatch("listenSSE");
        }, 30000);
      };
    },

    async getIPAddress({ dispatch }) {
      const timeout = (ms) =>
        new Promise((resolve, reject) => setTimeout(() => reject(new Error("Timeout")), ms));
      const urls = [
        "https://checkip.amazonaws.com/",
        "https://api.ip.pe.kr/json/",
        "https://ident.me",
        "https://api.ipify.org",
      ];

      const fetchWithTimeout = (url, ms) => {
        return Promise.race([axios.create({ withCredentials: false }).get(url), timeout(ms)]);
      };

      const requests = urls.map((url) => fetchWithTimeout(url, 1000));

      try {
        const response = await Promise.any(requests);
        if (response) {
          if (response.data.ip) {
            return response.data.ip;
          }
          return response.data;
        }
      } catch (error) {
        dispatch("dialog/alert", ["error", "통신 오류가 발생했습니다."], { root: true });
        return false;
      }
    },

    // 회사정보 가져오기
    async appInitialized({ state, commit, dispatch }) {
      new Vue().$socket.emit(
        "configs.get",
        {
          key: "info",
        },
        (resp) => {
          if (resp.result === "success") {
            commit("companyInfo", resp.value.value);
            document.title = `${state.companyInfo.name} 관리자`;
            console.log("app initialized");
            dispatch("authorization");
          } else {
            dispatch("dialog/alert", ["error", "오류가 발생했습니다."], { root: true });
            console.error(resp);
          }
        }
      );
    },

    async authorization({ commit, dispatch, state }, type = null) {
      if (state.isRefreshing) {
        throw new Error("Operation in progress");
      }
      commit("dialog/progress", true, { root: true });
      const success = (user) => {
        commit("userInfo", user);
        commit("verified", true);
        commit("initialized", true);
        commit("connected", true);
        dispatch("listenSSE");
        if (type !== 0) {
          dispatch("dialog/alert", ["success", `${user.admin_name} 님 반갑습니다.`], {
            root: true,
          });
        }
        commit("dialog/progress", false, { root: true });
      };
      const fail = () => {
        commit("verified", false);
        commit("initialized", true);
        commit("userInfo", null);
        commit("dialog/progress", false, { root: true });
      };

      try {
        const csrfToken = await axios.get("/");
        console.log(csrfToken);
        const url = `/init/tokenVerify`;
        let { data: resp } = await axios.post(url);
        if (resp.result !== "success") {
          throw new Error("Token verify failed");
        }

        const user = JSON.parse(JSON.stringify(resp.userInfo));

        const myIP = await dispatch("getIPAddress");
        const { data: ipCheck } = await axios.post(`/init/getInfo`, { info: "ip" });

        if (ipCheck.result !== "success" || !myIP) {
          throw new Error("IP check failed");
        }

        const allowIPArray = JSON.parse(ipCheck.value[0].value || "[]");
        const myIPnet = myIP.split(".").slice(0, -1).concat("*").join(".");

        if (allowIPArray.length === 0 || allowIPArray.some((row) => row.ip === myIPnet)) {
          return success(user);
        }

        dispatch("dialog/alert", ["error", `허용되지 않는 접속입니다.`], {
          root: true,
        });
        return fail();
      } catch (error) {
        if (error.message === "Token verify failed") {
          const result = await dispatch("refreshToken");
          if (result.data.message === "refresh_success") {
            return await dispatch("authorization", type);
          }
        } else {
          fail();
        }
      }
    },
    async refreshToken({ commit, state, dispatch }) {
      if (state.isRefreshing) {
        throw new Error("Operation in progress");
      }
      commit("refresh_request", true);
      try {
        const result = await axios.post("/init/refresh");
        if (result.data.result !== "refresh_success") {
          dispatch("logout");
        }
        commit("refresh_request", false);
        return result;
      } catch (error) {
        dispatch("logout");
        throw error;
      } finally {
        commit("refresh_request", false);
      }
    },
    async permissionToMenus({ commit }, per) {
      const menuArr = [];
      commit("menus", menuArr);
      const keys = Object.keys(per);
      const permission = { ...per };
      for (let i = 0; i < keys.length; i += 1) {
        const menus = { ...permission[keys[i]].menu };
        const menuKeys = Object.keys(menus);
        for (let j = 0; j < menuKeys.length; j += 1) {
          if (menuKeys[j] !== "notice" && menus[menuKeys[j]].bool === true) {
            if (keys[i] === "boards") {
              // const isDisable = boards.find((r) => String(r.no) === menuKeys[j]) === undefined;
              const isDisable = true;
              if (isDisable === false) {
                menuArr.push({
                  path: `boards-${menuKeys[j]}`,
                  icon: menuIcons.boards,
                  name: menus[menuKeys[j]].name,
                });
              }
            } else if (menuKeys[j] !== "sms" && menuKeys[j] !== "regist") {
              menuArr.push({
                path: menuKeys[j],
                icon: menuIcons[menuKeys[j]],
                name: menus[menuKeys[j]].name,
              });
            }
          }
        }
      }
    },
  },
};
